export enum GTMEventName {
  CompanyTypeChosen = 'companyTypeChosen',
  EnteredShareholders = 'enteredShareholders',
  OpenedKYC = 'openedKYC',
  OpenedPayment = 'openedPayment',
  PaidIncorporation = 'paidIncorporation',
  ConnectedBankAccount = 'connectedBankAccount',
  CreatedBankAccount = 'createdBankAccount',
  UploadedCSVStatement = 'uploadedCSVStatement',
  ImportedCSVStatement = 'importedCSVStatement',
  UploadedExpence = 'uploadedExpence',
  ApprovedExpenceData = 'approvedExpenceData',
  OpenedChat = 'openedChat',
  WroteToChat = 'wroteToChat',
  OpenedPayroll = 'openedPayroll',
  InvitedUser = 'invitedUser',
  AcceptedInvite = 'acceptedInvite',
  OpenedReports = 'openedReports',
  OpenedProjects = 'openedProjects',
  MadeChangesInCategories = 'madeChangesInCategories',
  AppliedTransactionFilters = 'appliedTransactionFilters',
  CreatedContact = 'createdContact',
  CreatedProject = 'createdProject',
  AssignedProjectToTransaction = 'assignedProjectToTransaction',
  AssignedCategoryToTransaction = 'assignedCategoryToTransaction',
  AccountCreated = 'accountCreated',
  KYCFiled = 'kycFiled',
}

export const sendGTMEvent = (
  name: GTMEventName,
  params?: Record<string, unknown>
): void => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: name,
      ...params,
    });
  } else {
    console.error('GTM not initialized');
  }
};

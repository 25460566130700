import {
  FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import { STORAGE_COMPANY_KEY, STORAGE_TOKEN_KEY } from '@constants';

type User = Schemas.User;
type Company = Schemas.Company;
type AccessRights = Schemas.AccessRights;
type FeaturesStatus = Schemas.FeaturesStatus;

type AccountContextProps = {
  isLogged: boolean;
  account: User | null;
  company: Company | null;
  companies: Company[];
  companyId: string | null;
  userAccess: AccessRights | null;
  featuresStatus: FeaturesStatus | null;
  accessToken: string | null;
  updateToken: (token: string) => void;
  setAccount: (account: User) => void;
  setCompany: (company: Company) => void;
  clearAccount: () => void;
  setUserAccess: (accessRights: AccessRights) => void;
  setFeaturesStatus: (featuresStatus: FeaturesStatus) => void;
};

export const AccountContext = createContext<AccountContextProps>(
  {} as AccountContextProps
);

export const AccountProvider: FC<PropsWithChildren> = ({ children }) => {
  const storageSelectedCompany = JSON.parse(
    localStorage.getItem(STORAGE_COMPANY_KEY)!
  );

  const storageAccessToken = localStorage.getItem(STORAGE_TOKEN_KEY);

  const [accountDetails, setAccountDetails] = useState<User | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company>(
    storageSelectedCompany
  );

  const [featuresStatus, setFeaturesStatus] = useState<FeaturesStatus | null>(
    selectedCompany?.featuresStatus || null
  );

  const [userAccess, setUserAccess] = useState<AccessRights | null>(
    selectedCompany?.userAccessRights || null
  );

  const [accessToken, setAccessToken] = useState<string | null>(
    storageAccessToken
  );

  const { i18n } = useTranslation();

  const setCompany = useCallback((company: Company) => {
    setSelectedCompany(company);

    if (company) {
      localStorage.setItem(STORAGE_COMPANY_KEY, JSON.stringify(company));
    }
  }, []);

  const setAccount = useCallback(
    (account: User) => {
      const selectedFirstCompany = account.companies[0];

      setAccountDetails(account);
      i18n.changeLanguage(account.locale);

      if (storageSelectedCompany) {
        const storageSelectedCompanyFromUser = account.companies.find(
          (c) => c.id === storageSelectedCompany.id
        );

        setCompany(storageSelectedCompanyFromUser || selectedFirstCompany);
        setUserAccess(
          storageSelectedCompanyFromUser?.userAccessRights ||
            selectedFirstCompany?.userAccessRights
        );
        setFeaturesStatus(
          storageSelectedCompanyFromUser?.featuresStatus ||
            selectedFirstCompany?.featuresStatus
        );
      } else {
        setCompany(selectedFirstCompany);
        setUserAccess(selectedFirstCompany?.userAccessRights);
        setFeaturesStatus(selectedFirstCompany?.featuresStatus);
      }

      setCompanies(account.companies);
    },
    [i18n, setCompany, storageSelectedCompany]
  );

  const updateToken = useCallback((token: string) => {
    localStorage.setItem(STORAGE_TOKEN_KEY, token);

    setAccessToken(token);
  }, []);

  const clearAccount = useCallback(() => {
    localStorage.removeItem(STORAGE_TOKEN_KEY);

    setAccountDetails(null);
    setAccessToken(null);
    setSelectedCompany({} as Company);
  }, []);

  return (
    <AccountContext.Provider
      value={{
        isLogged: !!localStorage.getItem(STORAGE_TOKEN_KEY),
        account: accountDetails,
        company: selectedCompany,
        companyId: selectedCompany?.id || null,
        companies,
        userAccess,
        accessToken,
        featuresStatus,
        updateToken,
        setAccount,
        setCompany,
        clearAccount,
        setUserAccess,
        setFeaturesStatus,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

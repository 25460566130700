import _ from 'lodash';
import {z} from 'zod';
import qs, { type ParseOptions, type StringifyOptions } from 'query-string';

const options: ParseOptions | StringifyOptions = {
  arrayFormat: 'bracket-separator',
  arrayFormatSeparator: '|',
  parseNumbers: true,
  parseBooleans: true,
  encode: true,
  skipEmptyString: true,
  skipNull: true,
};

export function stringifySearchParams<T extends Record<string, unknown>>(data?: T): string {
  return qs.stringify(data || {}, options)
}

/** Object of specified schema and possibly other values */
export type ObjectWithOtherKeys<T extends z.AnyZodObject> = z.infer<T> & {[key: string]: unknown}

export function parseSearchParams<T extends z.AnyZodObject>(search: string, schema: T): ObjectWithOtherKeys<T> | null {
  const data = qs.parse(search, {...options, })
  const parsed = schema.passthrough().safeParse(data)
  if (parsed.success) {
    return parsed.data
  } else {
    console.error(parsed.error);
    return null;
  }
}


/** @deprecated use stringifySearchParams instead */
export const setSearchParams = <T>(data: T | undefined) =>
  qs.stringify(data || {}, options);

/** @deprecated use parseSearchParams instead */
export const getSearchParams = <T>(search: string): T =>
  qs.parse(search, options) as T;
